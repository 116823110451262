<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Portada' }, { name: 'Crear' }]" />
    <v-card>
      <v-toolbar card color="primary" dark>
        <v-toolbar-title>Crear Portada</v-toolbar-title>
      </v-toolbar>
      <br />
      <v-form ref="form" v-model="validForm" lazy-validation @submit.prevent="submitPortada">
        <v-container fluid grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <v-fab-transition>
                <v-btn
                  v-show="imageUrlDesktop"
                  color="pink"
                  small
                  dark
                  absolute
                  left
                  fab
                  @click="deleteImageDesktop()"
                  >X</v-btn
                >
              </v-fab-transition>
              <el-upload
                class="avatar-uploader"
                action
                name="imagen"
                :http-request="onFilePickedDesktop"
                :show-file-list="false"
                :before-upload="beforeImageUploadDesktop"
              >
                <img v-if="imageUrlDesktop" :src="imageUrlDesktop" class="avatar" />

                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <label class="grey--text text--darken-1">Subir imagen escritorio (Obligatorio)</label>
            </v-flex>
            <v-flex xs12 md6>
              <v-fab-transition>
                <v-btn
                  v-show="imageUrlMovil"
                  color="pink"
                  small
                  dark
                  absolute
                  fab
                  @click="deleteImageMovil()"
                  >X</v-btn
                >
              </v-fab-transition>
              <el-upload
                class="avatar-uploader"
                action
                name="imagen"
                :http-request="onFilePickedMovil"
                :show-file-list="false"
                :before-upload="beforeImageUploadMovil"
              >
                <img v-if="imageUrlMovil" :src="imageUrlMovil" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <label class="grey--text text--darken-1">Subir imagen movil (Obligatorio)</label>
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.nombre"
                :disabled="processingForm"
                label="Nombre (Obligatorio)"
                outline
                :rules="rules.nombre"
                :error="!!formErrors.nombre"
                :error-messages="formErrors.nombre"
                @keyup="
                  () => {
                    formErrors.nombre = undefined;
                    delete formErrors.nombre;
                  }
                "
              />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.posicion"
                :disabled="processingForm"
                label="Posición"
                outline
                :rules="rules.posicion"
                :error="!!formErrors.posicion"
                :error-messages="formErrors.posicion"
                @keyup="
                  () => {
                    formErrors.posicion = undefined;
                    delete formErrors.posicion;
                  }
                "
              />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.titulo"
                :disabled="processingForm"
                label="Descripción"
                outline
                :rules="rules.titulo"
                :error="!!formErrors.titulo"
                :error-messages="formErrors.titulo"
                @keyup="
                  () => {
                    formErrors.titulo = undefined;
                    delete formErrors.titulo;
                  }
                "
              />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.descripcion"
                :disabled="processingForm"
                label="Link (colocar la url completa ej: https://tienda...)"
                outline
                :rules="rules.descripcion"
                :error="!!formErrors.descripcion"
                :error-messages="formErrors.descripcion"
                @keyup="
                  () => {
                    formErrors.descripcion = undefined;
                    delete formErrors.descripcion;
                  }
                "
              />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-autocomplete
                v-model="form.estado"
                :items="estados"
                dense
                outline
                clearable
                small-chips
                label="Seleccionar Estado"
                item-text="nombre"
                item-value="id"
                :error="!!formErrors.estado"
                :error-messages="formErrors.estado"
                @change="
                  () => {
                    formErrors.estado = undefined;
                    delete formErrors.estado;
                  }
                "
              />
            </v-flex>
          </v-layout>
        </v-container>
        <center>
          <v-btn
            type="submit"
            color="success"
            :disabled="!validForm || processingForm"
            :loading="processingForm"
            >Guardar</v-btn
          >
          <v-btn color="error" @click="$router.push({ name: 'ListPortada' })">
            Volver
          </v-btn>
        </center>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Crear Portada" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs")
  },
  data() {
    return {
      formErrors: {},
      activeBtn: 2,
      avatar: "",
      imageNameDesktop: "",
      imageNameMovil: "",
      imageUrlDesktop: "",
      imageUrlMovil: "",
      imageFile: "",
      file: "",
      form: {
        imagen_escritorio: "",
        imagen_moviles: "",
        imagen_url: "",
        imagen_path: "",
        nombre: "",
        estado: 1,
        titulo: "",
        posicion: 99999,
        descripcion: ""
      },
      estados: [
        { id: 0, nombre: "inactivo" },
        { id: 1, nombre: "activo" }
      ],
      validForm: true,
      processingForm: false,
      rules: {
        estado: [v => !!v || "El tipo es requerido"],
        imagen: [v => !!v || "La imagen es requerida"]
      }
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  methods: {
    ...mapActions({
      createPortada: "portadas/createPortada"
    }),
    deleteImageDesktop() {
      this.imageUrlDesktop = null;
    },
    deleteImageMovil() {
      this.imageUrlMovil = null;
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePickedDesktop(e) {
      this.form.imagen_escritorio = e.file;
      if (e.file !== undefined) {
        this.imageNameDesktop = e.file.name;
        if (this.imageNameDesktop.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(e.file);
        fr.addEventListener("load", () => {
          this.imageUrlDesktop = fr.result;
          this.imageFile = e.file;
        });
      } else {
        this.imageNameDesktop = "";
        this.imageFile = "";
        this.imageUrlDesktop = "";
      }
    },
    onFilePickedMovil(e) {
      this.form.imagen_moviles = e.file;
      if (e.file !== undefined) {
        this.imageNameMovil = e.file.name;
        if (this.imageNameMovil.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(e.file);
        fr.addEventListener("load", () => {
          this.imageUrlMovil = fr.result;
          this.imageFile = e.file;
        });
      } else {
        this.imageNameMovil = "";
        this.imageFile = "";
        this.imageUrlMovil = "";
      }
    },
    beforeImageUploadMovil(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$notify.info({
          title: "Info",
          message: "La imagen tiene que estar en formato JPG ó PNG"
        });
        return isJPG && isLt5M;
      }
      if (!isLt5M) {
        this.$notify.info({
          title: "Info",
          message: "La imagen no puede exceder los 5MB de tamaño"
        });
        return isJPG && isLt5M;
      }
      return isJPG && isLt5M;
    },
    beforeImageUploadDesktop(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$notify.info({
          title: "Info",
          message: "La imagen tiene que estar en formato JPG ó PNG"
        });
        return isJPG && isLt5M;
      }
      if (!isLt5M) {
        this.$notify.info({
          title: "Info",
          message: "La imagen no puede exceder los 5MB de tamaño"
        });
        return isJPG && isLt5M;
      }
      return isJPG && isLt5M;
    },
    submitPortada() {
      if (!this.$refs.form.validate()) return false;
      let formData = new FormData();
      formData.append("imagen_escritorio", this.form.imagen_escritorio);
      formData.append("imagen_moviles", this.form.imagen_moviles);
      formData.append("nombre", this.form.nombre);
      formData.append("estado", this.form.estado);
      formData.append("titulo", this.form.titulo);
      formData.append("posicion", this.form.posicion);
      formData.append("descripcion", this.form.descripcion);
      this.processingForm = true;
      this.createPortada({ data: formData })
        .then(response => {
          this.processingForm = false;
          this.$router.push({ name: "ListPortada" });
        })
        .catch(error => {
          this.processingForm = false;
        });
    }
  }
};
</script>
